<template>
  <div class="zchjs">
    <div class="header">
      <div class="h1">人力资源</div>
      <div class="h2">人才计划 助力企业经济腾飞</div>
    </div>
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
        <el-breadcrumb-item  :to="{ path: '/rlzy' }">人力资源</el-breadcrumb-item>
        <el-breadcrumb-item  :to="{ path: '/rlzylist' }">找工作</el-breadcrumb-item>
        <el-breadcrumb-item >详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="flex">
        <div class="flex-1">
          <div class="compxq ">
            <div >
              <div class="comptitle">
                <div class="ch1">
                  <div class="name">
                    {{datas.title}}
                    <span class="money">{{datas.minSalary}}-{{datas.maxSalary}}元/月</span>
                  </div>
                  <div class="ch2">{{datas.jobName}}</div>
                  <div>
                    <el-tag size="small" type="info" style="margin-right: 15px" v-for="(li,index) in datas.welfareNames.split(',')" :key="index">{{li}}</el-tag>
                  </div>
                </div>
                <div class="datitle"></div>
                <el-descriptions :colon="false" class="margin-top"  :column="1" >
                  <el-descriptions-item label="工作性质">{{datas.nature}}</el-descriptions-item>
                  <el-descriptions-item label="工作年限">{{datas.jobYears}}</el-descriptions-item>
                  <el-descriptions-item label="行业类别">{{datas.categoryName}}</el-descriptions-item>
                  <el-descriptions-item label="招聘人数">{{datas.recruitNumber}}</el-descriptions-item>
                  <el-descriptions-item label="学历">{{datas.eduBack}}</el-descriptions-item>
                  <el-descriptions-item ><el-button style="margin-left: 500px" type="danger" @click="shenqing">申请职位</el-button></el-descriptions-item>
                </el-descriptions>
              </div>
            </div>
          </div>
          <div style="background: #fff;padding: 25px;border-radius: 4px">
            <div class="compdesc ">
              <div class="jianjie">岗位职责:</div>
              <div style="padding: 25px"  v-html="datas.workDetail"></div>
              <div class="jianjie">任职要求:</div>
              <div style="padding: 25px"  v-html="datas.workDemand"></div>
              <div class="jianjie">联系人:{{datas.contacts}}</div>
              <div class="jianjie">联系电话:{{datas.phone}}</div>
              <div class="jianjie">工作地点:{{datas.provinceName}}{{datas.cityName}}{{datas.areaName}}{{datas.addressName}}</div>
            </div>
          </div>
        </div>
        <div class="crights">
          <div style="padding: 15px;font-weight: bolder">您可能感兴趣的职位</div>
          <div class="tuijian" v-for="(li,index) in datas1" @click="gotoxq(li)" :key="index">
            <div style="font-weight: bolder">{{li.title}}</div>
            <div style="line-height: 35px">{{li.nature}}-{{li.jobName}}</div>
            <div style="line-height: 25px;color: red">{{li.minSalary}}-{{li.maxSalary}}/月</div>
            <div style="margin-top: 5px"><el-tag size="small" type="info" style="margin-right: 15px;margin-bottom: 5px" v-for="(lis,index) in li.welfareNames.split(',')" :key="index">{{lis}}</el-tag></div>
          </div>
        </div>
      </div>

    </div>
    <el-dialog title="申请" width="600px" append-to-body :visible.sync="dialogTableVisible">
      <avue-form :option="sqoption" v-model="userForm" @submit="submitSwitch"></avue-form>
    </el-dialog>
  </div>

</template>

<script>
import { fiveyears } from '../../utils/index'
import {policyindex, policypage} from "../../request/moudle/zcfw";
import {enterprisedetail, parklist} from "../../request/moudle/park";
import fujian from "../common/fujian.vue";
import {humanrecruitdetail} from "../../request/moudle/rlzy";
import Cookies from "js-cookie";
export default {
  name: "zchj",
  components: {fujian},
  data(){
    return {
      datas:'',
      datas1:'',
      userForm:{},
      dialogTableVisible:false,
      sqoption:{
        column: [
          {
            label: '申请人',
            prop: 'name',
            span: 24,
            type: 'input'
          },
          {
            label: '简介',
            prop: 'detail',
            span: 24,
            type: 'textarea'
          },
          {
            label: '联系电话',
            prop: 'phone',
            span: 24,
            type: 'input'
          },
          {
            label: '简历附件',
            prop: 'files',
            type: 'upload',
            multiple: true,
            span: 24,
            limit: 1,
            dataType: 'string',
            propsHttp: {
              url: 'link',
              res: 'data'
            },
            headers: {
              'Authorization': `Basic ${Base64.encode(`pc:idsp-pc`)}`,
              'Tenant-Id': Cookies.get('Tenant-Id'),
              'Blade-Auth': 'bearer ' + Cookies.get('saber-access-token')
            },
            action:'/api/blade-resource/oss/endpoint/put-file'
          },
        ]
      },
    }
  },
  created() {
    this.loadxq()
  },
  methods:{
    submitSwitch(form,done){
      this.userForm.recruitId=this.$route.query.id
      this.$api.rlzy.humanrecruitapply(this.userForm).then((res)=>{
        this.$message.success('提交成功')
        this.dialogTableVisible=false
        done()
      },()=>{
        done()
      })
    },
    shenqing(item){
      this.dialogTableVisible=true
    },
    goBack() {
      this.$router.go(-1)
    },
    gotoxq(item){
      this.$router.push({
        path:'/rlzyfind',
        query:{
          id:item.id,
        }
      })
      this.$router.go(0)
    },
    loadxq(){
      this.$api.rlzy.humanrecruitdetail({
        id:this.$route.query.id
      }).then((res)=>{
        this.datas=res.data.detail
        this.datas1=res.data.recommendList
      })
    },
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-descriptions-item__label{
  min-width: 120px;
}
.zchjs{
  background: #F5F5F5;
}
.datitle{
  text-align: right;
  border-bottom: 1px solid #DCDFE6;
  padding: 15px 25px;
  margin: 15px 0;
  .name{
    font-size: 16px;
    font-weight: bolder;
    float: left;
  }
}
.crights{
  width: 350px;
  background: #fff;
  border-radius: 4px;
  padding: 5px;
  margin: 25px 25px 0 25px;
  .tuijian{
    border-top: 1px solid #D7D7D7;
    padding: 15px;
    cursor: pointer;
  }
}
.header{
  height: 220px;
  background: url("../../assets/banner/yqgl.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 90px;
  text-align: center;
  color: #fff;
  .h1{
    font-size: 42px;
    font-weight: 500;
  }
  .h2{
    font-size: 22px;
    line-height: 80px;
  }
}
.content{
  width: 1200px;
  margin: 0px auto;
  padding: 25px;
  color: #292929;
  /deep/ .el-col{
    padding: 15px ;
  }
  .compdesc{
    font-size: #3E3E3E;
    text-align: left;
    margin-top: 10px;
  }
  .margin-top{
    padding:10px 25px ;
  }
  .comptitle{
    background: #fff;
    border-radius: 4px;
    .ch1{
      padding: 39px  39px 0 39px;
    }
    .ch2{
      line-height: 55px;
    }
    .name{
      font-weight: bolder;
      font-size: 16px;
    }
    .money{
      float: right;
      font-weight: 500;
      color: red;
    }
  }
  .jianjie{
    font-size: 14px;
    font-weight: bolder;
    margin: 25px 0;
  }
  .compxq{
    margin: 25px 0;
    .images{
      width: 250px;
      padding: 38px;
      background: #fff;
      margin-right: 15px;
      border-radius: 4px;
    }
  }
}
</style>